import axios, {AxiosRequestConfig} from "axios";
import qs from "qs"
import { Config } from "../../domain/config";
import {
    Http,
    HttpFetchOptions, HttpFetchResponse,
    HttpPostProcessor, HttpPreProcessor } from "../../domain/extensions/http";
import { Ground } from "../../domain/ground";
import { Logger } from "../../domain/extensions/logger";

export default class AxiosHttp implements Http {

    private static paramsSerializer: (params: any) => string = (params) => {
        return qs.stringify(params, {
            arrayFormat: "brackets",
            encode: true,
        });
    }

    public name: string = "axios";

    //#region Private properties

    /** App */
    private log: Logger = null as any;

    /** Pre processors */
    private preProcessors: HttpPreProcessor[] = [];

    /** Post processors */
    private postProcessors: HttpPostProcessor[] = [];

    //#endregion

    public initialize(config: Config, app: Ground): void {
        this.log = app.framework.log;
    }

    public async fetch(uri: string, options: HttpFetchOptions): Promise<HttpFetchResponse> {
        // Apply pre processors
        for (const processor of this.preProcessors) {
            options = await processor(options);
        }

        // Build the configuration
        const config: AxiosRequestConfig = {
            url: uri,
            method: options.method,
            data: options.body,
            headers: options.headers,
            params: options.method === "GET" ? options.body : undefined,
        }

        if (options.serializeParams) {
            config.paramsSerializer = AxiosHttp.paramsSerializer
        }

        this.log.debug(`HTTP request: ${uri}`, config);

        // Execute request
        let response;
        try {
            response = await axios(uri, config);
        } catch (error) {

            // Log if this was not a network error
            // If there is no response at all, it shouldn't be a server error - maybe connection!
            if (
                error.response && error.response.status > 0
            ) {
                this.log.warn(`HTTP response failed: ${error.message}`, {
                    uri,
                    config,
                    error,
                });
            }

            if (error.response) {
                throw {
                    status: error.response.status,
                    headers: error.response.headers as any,
                    body: error.response.data.error || error.response.data,
                }
            } else {
                throw {
                    status: -1,
                    headers: {},
                    body: error.request || error.message,
                }
            }
        }

        // Do something with the response
        let responseBodyParsed = response.data;
        this.log.debug(`HTTP response: ${uri}`, {
            response,
            data: responseBodyParsed,
        });

        // Apply post processors
        for (const processor of this.postProcessors) {
            responseBodyParsed = await processor(response.status, response.headers as any, responseBodyParsed);
        }

        return {
            status: response.status,
            headers: response.headers as any,
            body: responseBodyParsed,
        };
    }

    public registerPreProcessor(callback: HttpPreProcessor): void {
        if (this.preProcessors.indexOf(callback) < 0) {
            this.preProcessors.push(callback);
        }
    }

    public registerPostProcessor(callback: HttpPostProcessor): void {
        if (this.postProcessors.indexOf(callback) < 0) {
            this.postProcessors.push(callback);
        }
    }

}
