import React from "react";

export interface useListReturn {
  /** Array of values */
  list: unknown[];
  /** Sets a new list value */
  set: React.Dispatch<React.SetStateAction<any[]>>;
  /** Resets the list value */
  reset: () => void;
  /** Pushes a new value to the list */
  push: (...values: any[]) => void;
  /** A function to sort the list values */
  sort: (fn?: (a: any, b: any) => number) => void;
  /** A function to filter the list values */
  filter: (fn: (val: any) => boolean) => void;
}

/**
 * Hook that facilitates the creation, filtering,
 * sorting and mutation of an array of values
 *
 * ## How to use inside a function component:
 * ```javascript
 * import { useList } from "@7egend/web.hooks";
 *
 * const Component = () => {
 *  const { list, set, reset, push, sort, filter } = useList(['Item A, 'Item B']);
 *
 *  return (
 *    <div>
 *      <button onClick={() => set(["New Item A"])}>Sets a new list</button>
 *      <button onClick={() => reset()}>Resets the list</button>
 *      <button onClick={() => push('Item C', 'Item D')}>Adds two values to the list</button>
 *      <button onClick={() => sort((a, b) => a > b)}>Sort the list</button>
 *      <button onClick={() => filter((item) => typeof item === "number")}>Filters the list (only numbers)</button>
 *    </div>
 *  )
 * }
 * ```
 */
export const useList = (initial?: unknown[]): useListReturn => {
  const [list, set] = React.useState<unknown[]>(initial || []);

  return {
    list,
    set,
    reset: () => set(initial || []),
    push: (...values) => set(prev => [...prev, ...values]),
    sort: (fn?) => set(prev => [...prev].sort(fn)),
    filter: fn => set(prev => prev.filter(fn))
  };
};

export default useList;
