import { Store } from "@7egend/web.core";
import { ContainersActions, ContainersState } from "./types";

const initialState: ContainersState = {}

const root: Store.Reducer<ContainersState> = (state: ContainersState = initialState, action: any) => {
    switch ((action as ContainersActions).type) {
        case "@@core.cms/GET_CONTAINER_SUCCESS":
            const newState = {...state}
            // Remove blocks that don't have content
            action.payload.forEach((payload: any) => payload.blocks = payload.blocks.filter((block: any) => block.content))
            // Save each container to the state
            action.payload.forEach((payload: any) => newState[payload.reference || payload.slug] = payload)
            return newState
        default:
            return state;
    }
}

export { root }
