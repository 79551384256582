import React from "react"
import { Size, Breakpoint } from "./types";

export interface SizeContextContent {
    size: Size;
    breakpoint: Breakpoint
}

const SizeContext = React.createContext<SizeContextContent>({
    size: {
        width: 0,
        height: 0,
    },
    breakpoint: Breakpoint.Desktop,
});

export { SizeContext };
