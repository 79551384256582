import { Store } from "@7egend/web.core";
import { SUBMIT_FORM_KEY } from "./types"
import { FormSubmitInput, FormAnswer } from "../../dlos/formTemplate";

//#region Submit Feedback

export function submitForm(secret: string, answers: FormAnswer[], email: string = "") {

    const request = new FormSubmitInput()
    request.secret = secret;

    request.body = {
        answers,
        email,
    }

    return Store.utils.actionDefaultProcessor(SUBMIT_FORM_KEY, request);
}

//#endregion
