import { BaseModel } from "@7egend/web.core.dlos";
import { Language } from "@7egend/web.core.geo/lib/dlos/language";
import { Block } from "./block";
import { DloInput, DloOutput } from "@7egend/web.core/lib/extensions/dlo";

export interface Container<T = BaseModel> extends BaseModel {
    name: string
    uuid?: string
    reference?: string
    slug?: string
    language?: Language
    maxSize?: number
    headerTitle?: string
    footerTitle?: string
    blocks: Array<Block<T>>
    style?: string
}

export class ContainerGetBySlugInput extends DloInput<{
    slug: string,
}> {
    public uri = "cms-containers/${slug}?fields=${fields}"
    public fields = "uuid,slug,reference,name,blocks(position,type,content(uuid,title,subtitle,text,call_to_action,link))"
}

export class ContainerGetBySlugOutput extends DloOutput<Container> { }
