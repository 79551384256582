import { CorePureComponent } from "../../base";

export interface Props {
    /** Route ID for RouterOutlet usage */
    to: string
}

export class RedirectRouteComponent extends CorePureComponent<Props> {

    public constructor(props: any, context: any) {
        super(props, context);
        this.fw.dom.getGlobal().location.href = this.props.to;
    }

    public render() {
        return null;
    }

}
