import React, { useState, useContext } from "react";
import { Generic } from "../../base/generic";
import { useFramework } from "../../components/useFramework";
import { I18n } from "@7egend/web.ground/lib/domain/extensions/i18n";
import { memoize } from "../../utils";
import { I18nContext, I18nContextValue } from "../../contexts/i18nContext";

export interface UseI18nCatalog {
  /**
   * Catalog mapping object.
   * Each key should be a language code containing its messages
   */
  [language: string]: object;
}

const loadCatalog = memoize(
  async (catalog: UseI18nCatalog, language: string) => {
    const generic = new Generic();
    const data = catalog;

    if (data[language]) {
      let languageData;
      const languageLoader = data[language];

      if (typeof languageLoader === "function") {
        languageData = await languageLoader();
      } else if (languageLoader instanceof Promise) {
        languageData = await languageLoader;
      } else {
        languageData = languageLoader;
      }

      generic.framework.i18n.loadCatalog(
        languageData.default || languageData,
        false,
        language,
      );
    }
  },
);

/**
 * Hook to expose the i18n utilities and load catalogs
 * ## How to use inside a function component:
 *
 * ## How to use
 *
 * _i18n/en.json_
 * ```json
 * {
 *  "hello": "Hello {name}!"
 * }
 * ```
 *
 * _i18n/pt.json_
 * ```json
 * {
 *  "hello": "Olá {name}!"
 * }
 * ```
 *
 * _component.tsx_
 * ```javascript
 * import { useI18n } from "@7egend/web.core";
 *
 * export default (props) => {
 *  const [translate, i18n, language] = useI18n({en, pt})
 *
 *  return <div>{translate("hello", {name: "User"})}</div>
 * }
 * ```
 */
export const useI18n = (
  catalog?: UseI18nCatalog,
): [I18n["translate"], I18n, string, I18nContextValue] => {
  // In the case where the catalog is not provider
  // Handle by context
  const i18nContext = useContext(I18nContext);

  // This is for catalogs to load
  const [isLoaded, setLoaded] = useState(catalog ? false : true);
  const framework = useFramework();
  const language = framework.i18n.getCurrentLanguage();
  const translate = React.useMemo(
    () => framework.i18n.translate.bind(framework.i18n),
    [language, isLoaded],
  );

  React.useEffect(() => {
    if (!catalog) {
      return;
    }

    loadCatalog(catalog || {}, language).then(() => {
      setLoaded(true);
    });
  }, [language]);

  return [translate, framework.i18n, language, i18nContext];
};
