import React from "react";
import { NavLink } from "react-router-dom";
import { CoreComponent } from "@7egend/web.core/lib/base/component"
import { memoizeOne } from "@7egend/web.core/lib/utils";

/**
 * Link Target
 */
export enum Target {
    self = 1,
    blank = 2,
}

export interface LinkProps {
    /** Target href/destination */
    href: string
    /* Target */
    target?: Target
    /** Make this link downloadable */
    download?: boolean,
    /** Title */
    title?: string,
    /** @deprecated Button class */
    classes?: string,
    /** Button class */
    className?: string,
    /**
     * Active class
     * @default "active"
     */
    activeClassName?: string
    /**
     * Locale aware url
     * @default true
     */
    useLocale?: boolean
    /** Click event callback */
    onClick?: (event: React.MouseEvent, props: LinkProps) => void
}

/**
 * # Component Link
 * This component renders an anchor html tag
 *
 * ## How to use
 *
 * Example:
 * ```
 * <LinkComponent
 *  href="link.com"
 *  download={false}
 *  title="Link Title"
 *  target={LinkTarget.blank}
 *  className="big-link"
 * />
 * ```
 *
 */

export class Link extends CoreComponent<LinkProps> {

    // set default props
    public static readonly defaultProps: LinkProps = {
        href: "",
        target: Target.self,
        download: false,
        useLocale: true,
        activeClassName: "active",
    }

    /**
     * Checks if given url is external
     */
    private isExternalUrl = memoizeOne((url: string) => {
        return url.indexOf("://") > -1
    })

    /**
     * Checks if given url includes the current language
     */
    private isWithLocale = memoizeOne((url: string) => {
        return this.fw.config.locale.available.some((language) => {
            return url.startsWith(`${language}/`) || url.startsWith(`/${language}`)
        })
    })

    /**
     * Gets the localized url for given href
     */
    private getLocalizedHref = memoizeOne((url: string) => {
        if (!this.isWithLocale(url)) {
            return `/${this.fw.i18n.getCurrentLanguagePublic()}${(!url || url.startsWith("/")) ? "" : "/"}${url}`
        }
        return url
    })

    /**
     * Gets the normalized URL
     * This will take into account external URLs that are actually internal
     */
    private getNormalizedUrl = memoizeOne((url: string, target: Target) => {
        if (target === Target.self) {
            const isClientSide = this.fw.dom.isClientSide();
            if (isClientSide) {
                // Check for the case the URL is external BUT is pointing to ourselves
                const currentOrigin: string = this.fw.dom.getGlobal().location.origin;
                if (url.startsWith(currentOrigin)) {
                    return url.replace(currentOrigin, "")
                }
            }
        }

        return url
    })

    public render() {
        const { className, children, target, title, download, activeClassName } = this.props
        const { useLocale, ...otherProps } = this.props
        let { href } = this.props

        // Render text if no link was given
        if (href == null) {
            return <a className={className}>{children}</a>
        }

        // Get normalized url
        href = this.getNormalizedUrl(href, target || Target.self)

        // Check if this is still external
        const external = this.isExternalUrl(href);

        if (external) {
            return (
                <a
                    href={href}
                    target={`_${Target[target!]}`}
                    download={download}
                    title={title}
                    className={className || this.props.classes}
                    onClick={this.onClick}
                    arial-label={title}
                >
                    {children}
                </a>
            )
        } else {
            // Check for language if available
            // TODO: this should be done in another level or at least allow to handle each link using an injected parser
            // but while we don't know where to put it, leave it here
            // The problem with this solution is that we are no longer capable to handle non-url-translated apps by default
            let hrefLocalized
            if (useLocale) {
                hrefLocalized = this.getLocalizedHref(href)
            }

            return (
                <NavLink
                    {...{ ...otherProps, target: undefined, className: className || this.props.classes }}
                    activeClassName={activeClassName}
                    to={hrefLocalized || href}
                    onClick={this.onClick}
                    arial-label={title}
                >
                    {children}
                </NavLink>
            )
        }
    }

    private onClick = (event: React.MouseEvent) => {
        if (this.props.onClick) {
            this.props.onClick(event, this.props)
        }
    }
}
