import { Config } from "../../domain/config";
import { I18n } from "../../domain/extensions/i18n";
import { Logger } from "../../domain/extensions/logger";
import { Ground } from "../../domain/ground";
import { Storage } from "../../domain/extensions/storage";
import { Http } from "../../domain/extensions/http";

const REGEX_REPLACE_VALUES = new RegExp(/({(\w*)})/ig)
const STORAGE_KEY = "__i18n";
const STORAGE_CURRENT_LANGUAGE_KEY = `${STORAGE_KEY}.current`

export default class MemoryI18n implements I18n {

    public name: string = "memory";

    //#region Private properties

    /** App */
    private app: Ground = null as any;

    /** Logger */
    private logger: Logger = null as any;

    /** Current Language code */
    private currentLanguage: string = "";

    /** Translations data */
    private translations: {[key: string]: Map<string, string>} = {};

    /** Subscribers for callback when language change */
    private subscribers: Array<(language: string) => void> = [];

    /** Subscribers for callback when catalog change */
    private catalogChangeSubscribers: Array<() => void> = [];

    /** Storage */
    private storage: Storage = null as any;

    /** Http proxy */
    private http: Http = null as any;

    //#endregion

    public initialize(config: Config, app: Ground): void {
        this.app = app;
        this.logger = app.framework.log;
        this.storage = app.framework.localStorage;
        this.http = app.framework.http;
    }

    //#region Public methods

    public getSavedLanguage(): string {
        return this.getLanguageFromStorage();
    }

    public getCurrentLanguage(): string {
        return this.currentLanguage;
    }

    public async setCurrentLanguage(language: string): Promise<void> {
        if (language in this.translations === false) {
            // Fetch the catalog
            const catalog = await this.fetchCatalog(language);

            // Load the catalog
            this.loadCatalog(catalog, false, language);
        }

        // Activate the language
        this.currentLanguage = language;
        this.setLanguageToStorage(language);

        // Notify subscribers
        this.subscribers.forEach((f) => {
            try { f(language) } catch (err) {
                // do nothing, ignore because it can be a failed or nonexisting callback
            }
        });
    }

    public getCurrentLanguagePublic(): string {
        return this.getCurrentLanguage();
    }

    public _(key: string, values?: object): string {
        return this.translate(key, values);
    }

    public translate(key: string, values?: object): string {
        // Try to get the key from the map
        let translated = this.translations[this.currentLanguage].get(key);

        if (!translated) {
            // this.logger.warn(`Translation not found for language ${this.currentLanguage} and key ${key}`);
            return translated || key
        }

        if (values && Object.keys(values).length > 0) {
            translated = translated.replace(REGEX_REPLACE_VALUES, (match: string, p1: string, p2: string) => (values as any)[p2] != null ? (values as any)[p2] : p1);
        }

        return translated || "";
    }

    public loadCatalog(data: { [key: string]: any; messages?: any }, replace: boolean = false, language?: string): void {
        // Get the language or use the default language
        language = language || this.currentLanguage;

        // Check if this catalog already exists and create if needed
        if (language in this.translations === false) {
            this.translations[language] = new Map()
        }

        // Get the translation repository
        const translations = this.translations[language];

        // Flatten data for faster search
        // Prefer data inside messages property for backwards compatibility
        const dataFlatten = this.flatten(data.messages || data);

        let dispatchChange = false

        // Try to update everything now
        for (const key in dataFlatten) {
            if (dataFlatten.hasOwnProperty(key)) {
                const value = dataFlatten[key];
                const translation = translations.get(key);

                // Update when there is no translation or the existing one is different and replace is true
                if (!translation || (translation && replace && translation !== value)) {
                    translations.set(key, value)
                    dispatchChange = true
                }
            }
        }

        if (dispatchChange) {
            this.catalogChangeSubscribers.forEach((f) => {
                try { f() } catch (err) {
                    // do nothing, ignore because it can be a failed or nonexisting callback
                }
            });
        }
    }

    public async fetchCatalog(language: string): Promise<{[key: string]: string}> {
        const catalog = (await this.http.fetch(`/locale/${language}.json`, {method: "GET"})).body;
        return catalog.messages || catalog;
    }

    public onLanguageChange(callback: (language: string) => void): () => void {
        this.subscribers.push(callback);
        return () => {
            this.subscribers = this.subscribers.filter((s) => s !== callback)
        }
    }

    public onCatalogChange(callback: () => void): () => void {
        this.catalogChangeSubscribers.push(callback);
        return () => {
            this.catalogChangeSubscribers = this.catalogChangeSubscribers.filter((s) => s !== callback)
        }
    }

    //#endregion

    //#region Private methods

    private flatten(data: any): { [key: string]: any } {
        const result: any = {};
        function recurse(cur: any, prop: any) {
            if (Object(cur) !== cur) {
                result[prop] = cur;
            } else if (Array.isArray(cur)) {
                let i = 0;
                let l = 0;
                for (i = 0, l = cur.length; i < l; i++) {
                    recurse(cur[i], prop + "[" + i + "]");
                }
                if (l === 0) {
                    result[prop] = [];
                }
            } else {
                let isEmpty = true;
                for (const p in cur) {
                    if (cur.hasOwnProperty(p)) {
                        isEmpty = false;
                        recurse(cur[p], prop ? prop + "." + p : p);
                    }
                }
                if (isEmpty && prop) {
                    result[prop] = {};
                }
            }
        }
        recurse(data, "");
        return result;
    }

    private getLanguageFromStorage(): string {
        return this.storage.get(STORAGE_CURRENT_LANGUAGE_KEY);
    }

    private setLanguageToStorage(value: string) {
        this.storage.set(STORAGE_CURRENT_LANGUAGE_KEY, value);
    }

    //#endregion
}
