import React from "react";
import { CoreComponent } from "../../base/component";
import { RouteConfig } from "../../base/config/route";
import { Route } from "react-router"
import { Switch, Redirect } from "react-router";
import { DefaultRouteComponent } from "./defaultRoute";
import { RedirectRouteComponent } from "./redirectRoute"
import { memoizeOne } from "../../utils";
import { RouteConfigAbstractNode } from "../../base/config/route";

export interface RouterOutletProps {
    /** RouterConfig ID */
    id?: string
    /** External Routes Source */
    routes?: RouteConfig[]
    /** Wrapper for all route components */
    componentWrapper?: React.ComponentType<any> | React.StatelessComponent<any>
}

interface RouteNodePath {
    route: RouteConfig
    pathRoutes: RouteConfig[]
    path: string
}

export class RouterOutlet extends CoreComponent<RouterOutletProps> {

    private processRoutes = memoizeOne((routes: RouteConfig[]) => {
        return routes.map((r) => {
            // Compute the target Component to render
            if (!r.component) {
                r.component = (props: any) => {
                    if (!("id" in r) || !r.id) {
                        this.fw.log.warn(`Route path '${r.path}' needs to have an id`)
                        return null
                    }
                    return (<DefaultRouteComponent {...props} id={(r as RouteConfigAbstractNode).id} />)
                }
            }

            // Wrap route if needed
            if (this.props.componentWrapper) {
                const Wrapper = this.props.componentWrapper
                const InnerComponent = r.component
                r.component = (props: any) => {
                    return <Wrapper><InnerComponent {...props} /></Wrapper>
                }
            }

            return r
        })
    })

    /**
     * Gets the route node.
     * Renders a new switch for all the children of the given route node.
     */
    public render() {
        // Get router outlet key
        const outletKey = this.props.id
        const routeData = this.props.routes || this.framework.config.routes

        // Get current language
        const language = this.fw.i18n.getCurrentLanguage()
        const languagePublic = this.fw.i18n.getCurrentLanguagePublic()

        // Locate the route node by key
        let routeNode: RouteNodePath | undefined

        if (outletKey) {
            routeNode = this.getRouteNodeByKey(language, routeData, outletKey)
        }

        // So there's something wrong, because the route wasn't found but there's a key or path
        // Stop here
        if (!routeNode && (outletKey)) {
            return null
        }

        // Prepare for route drawing
        let routes: RouteConfig[] = routeNode && "routes" in routeNode.route ? routeNode.route.routes || [] : routeData
        const variables: { [key: string]: string } = {
            language: languagePublic,
        }

        routes = this.processRoutes(routes)

        return (
            <Switch>
                {routes.map((r, idx) => {
                    // Calculate the new path
                    let path: string = r.locale && language in r.locale ? r.locale[language] : r.path as string || ""

                    // Check if the route is absolute or relative
                    const isRelativePath: boolean = !path.startsWith("/");

                    let routeNodePath = "";

                    // Prepend the parent
                    if (routeNode) {
                        routeNodePath = routeNode.route.locale && (language in routeNode.route.locale) ?
                            routeNode.route.locale[language] : routeNode.route.path! as string;

                        routeNodePath = [routeNode.path, routeNodePath].join("/");
                    }

                    if (isRelativePath) {
                        path = [routeNodePath, path].join("/");

                        // Start with slash
                        path = `/${path}`

                        // Remove duplicated slash
                        path = path.replace(/\/\//g, "/")
                    } else {
                        path = `/:language${path}`
                    }

                    // Render redirect
                    if ("redirectTo" in r && r.redirectTo) {
                        const isClientSide = this.fw.dom.isClientSide();
                        const currentOrigin: string = isClientSide ? this.fw.dom.getGlobal().window.location.origin : "";
                        const isFullPath: boolean = r.redirectTo.indexOf(currentOrigin) > -1;
                        const isAbsolutePath: boolean = !isFullPath && r.redirectTo.indexOf("http") === 0;

                        let to = r.redirectToLocale && language in r.redirectToLocale ? r.redirectToLocale[language] : r.redirectTo;

                        if (isFullPath) {
                            to = to.replace(currentOrigin, "");
                        }

                        if (!isAbsolutePath && !to.startsWith("/")) {
                            to = [routeNodePath, to].join("/");
                        }

                        to = this.getRoutePathWithVariables(to, variables) || ""

                        if (!isAbsolutePath) {
                            return <Redirect key={path} path={path} to={to} exact={r.exact} />
                        } else {
                            r.component = () => (<RedirectRouteComponent to={to} />)
                        }
                    }

                    // Render
                    return (<Route key={path} {...r} path={path} />)
                })}
            </Switch>
        )
    }

    /**
     * Gets a RouteNode by key.
     * The can in this case is the route ID.
     */
    private getRouteNodeByKey = (language: string, routes: RouteConfig[], key: string, path: string = "", pathRoutes: RouteConfig[] = []): RouteNodePath | undefined => {
        for (const route of routes) {
            if ("id" in route && route.id === key) {
                return {
                    route,
                    pathRoutes,
                    path,
                }
            }
            if ("routes" in route && route.routes) {
                const routePath = route.locale && language in route.locale ? route.locale[language] : route.path || "";

                const childRoute = this.getRouteNodeByKey(language, route.routes, key, [path, routePath].join("/").replace("//", "/"), [...pathRoutes, route])
                if (childRoute) {
                    return childRoute
                }
            }
        }
    }

    /**
     * Gets given path with variables
     */
    private getRoutePathWithVariables = (path: string, variables: { [key: string]: string }) => {
        return path.replace(/{(\w+)}/g, (match, token) => variables[token.toLowerCase()] || token);
    }

}
