import { useState, useEffect } from "react";

export interface useMousePositionReturn {
  x: number;
  y: number;
}

export const useMousePosition = (initialCoords = { x: 0, y: 0 }) => {
  const [mousePos, setMousePos] = useState(initialCoords);

  useEffect(() => {
    const trackMouse = (e: MouseEvent) => {
      setMousePos({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener("mousemove", trackMouse);

    return () => {
      window.removeEventListener("mousemove", trackMouse);
    };
  });

  return mousePos;
};

export default useMousePosition;
