import { useRef, useEffect, useState, useCallback } from "react";

/**
 * Hook that allows you to set a setTimeout
 * with configurable delay, execution limit and callback function.
 *
 * Returns a number which corresponds to the current timer iteration.
 *
 * ## How to use inside a function component:
 * ```javascript
 * import { useTimer } from "@7egend/web.hooks";
 *
 * const Component = () => {
 *  const tickCount = useTimer(2000, 3, myCallback);
 *
 *  const myCallback = React.useCallback(() => {
 *    console.log('will call myCallback function every 2 seconds for 3 times')
 *  }, []);
 *  return <p></p>
 * }
 * ```
 */
export const useTimer = (
  delay: number = 1000,
  limit: number = Infinity,
  callback: () => void = () => undefined
): number => {
  const savedCallback = useRef<any>(callback);
  let [tickCount, updateCount] = useState(0);

  const tick = useCallback(() => {
    updateCount(prevCount => prevCount + 1);
    if (savedCallback.current) {
      savedCallback.current();
    }
  }, [savedCallback]);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let interval: any;

    if (tickCount >= limit) {
      return () => clearInterval(interval);
    } else {
      interval = setInterval(tick, delay);
      return () => clearInterval(interval);
    }
  }, [delay, limit, tickCount]);

  return tickCount;
};

export default useTimer;
