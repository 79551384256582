import { Component as GroundComponent } from "@7egend/web.ground/lib/components/component";
import { Framework } from "./framework";

let groundComponent: GroundComponent;

class Generic {
    public framework: Framework
    public fw: Framework

    constructor() {
        if (!groundComponent) {
            groundComponent = new GroundComponent()
        }
        this.framework = this.fw = groundComponent.fw as any
    }
}

export { Generic }
