import { Store } from "@7egend/web.core";
import { CategoriesActions, CategoriesState } from "./types";

const initialState: CategoriesState = {}

const root: Store.Reducer<CategoriesState> = (state: CategoriesState = initialState, action: any) => {
    switch ((action as CategoriesActions).type) {
        case "@@core.cms/GET_CATEGORIES_SUCCESS":
            return {...state, ...action.payload}
        default:
            return state;
    }
}

export { root }
