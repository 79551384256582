import { Store } from "@7egend/web.core"

import * as sectionsContainer from "./sectionsContainers"
import * as containers from "./containers"
import * as categories from "./categories"
import * as menu from "./menu"

import { CmsState } from "./state"

export function reducer(state: CmsState = {} as any, action: Store.Action) {
  return {
    sectionsContainers: sectionsContainer.reducer.root(state.sectionsContainers, action),
    containers: containers.reducer.root(state.containers, action),
    categories: categories.reducer.root(state.categories, action),
    menu: menu.reducer.root(state.menu, action),
  }
}
