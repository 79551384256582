import { Store } from "@7egend/web.core";
import { MenuState, MenuActions } from "./types";

const initialState: MenuState = {
    data: undefined,
    loading: false,
    loaded: false,
    error: undefined,
}

const root: Store.Reducer<MenuState> = (state: MenuState = initialState, action: any) => {
    switch ((action as MenuActions).type) {
        case "@@core.cms/GET_MENU_START":
            return {...state, loading: true, loaded: false, error: undefined};
        case "@@core.cms/GET_MENU_SUCCESS":
            return {...state, loading: false, loaded: true, data: action.payload, error: undefined};
        case "@@core.cms/GET_MENU_ERROR":
            return {...state, loading: false, loaded: true, error: action.payload.body};
        default:
            return state;
    }
}

export { root }
