import React from "react";
import { CoreComponent } from "../../base";

export interface Props {
    /** Element to detect the scroll position */
    element?: React.RefObject<any>
    /** Container that handles the scroll */
    container?: React.RefObject<any>
    /** Callback for  */
    onScroll?: (position: number, event: React.ChangeEvent) => void
}

interface State {
    /** Scroll position */
    scroll: number
}

/**
 * # ScrollListener component
 * Handles the scroll event for a given container.
 * If can be used with or without render props.
 *
 * ## Examples
 * ```HTML
 * <ScrollListener>
 *  {position => (<p>Position: {position} </p>)}
 * </ScrollListener>
 * ```
 *
 * ```HTML
 * <ScrollListener onScroll={(position) => this.setState({position})}>
 *  <p>Position: {this.state.position}</p>
 * </ScrollListener>
 * ```
 */
export class ScrollListener extends CoreComponent<Props, State> {

    public state: State = {
        scroll: this.props.element ? this.props.element.current.scrollTop : this.fw.dom.getDocument().body.scrollTop,
    }

    private subscriber?: () => void

    public componentDidMount() {
        // Start listening
        this.subscriber = this.fw.dom.onScrollChange(this.recordPosition, this.props.container)
    }

    public componentWillUnmount() {
        // Unregister subscriber
        if (this.subscriber) {
            this.subscriber();
        }
    }

    public render() {
        // Call the render for render props with the scroll
        if (typeof this.props.children === "function") {
            return (this.props.children as any)(this.state.scroll);
        }

        return this.props.children || null
    }

    //#region Private methods

    private recordPosition = (event: any) => {
        const { onScroll } = this.props
        const document = this.fw.dom.getDocument()

        let scrollTop

        if (document.scrollingElement) {
            scrollTop = document.scrollingElement.scrollTop
        } else {
            if (event.target === document) {
                scrollTop = (event.target as any).body.scrollTop
            } else {
                scrollTop = (event.target as any).scrollTop
            }
        }

        if (onScroll) {
            try {
                onScroll(scrollTop, event)
            } catch (error) {
                this.fw.log.error("Failed to call onScroll callback", error);
            }
        }

        this.setState({ scroll: scrollTop })
    }

    //#endregion

}
