import React from "react";
import { CoreComponent, CoreEvents } from "../../base";
import { BrowserRouter as Router } from "react-router-dom"
import Moment from "react-moment";
import moment from "moment";
import { WindowSizeProvider, WindowSizeBreakpointCalculator } from "../windowSize";
import { RouterOutlet } from "../routerOutlet/routerOutlet";
import { I18nProvider } from "../../contexts/i18nContext";

export interface AppOptions {
    errorComponent?: React.ReactElement
    breakpoint?: {
        calculator?: WindowSizeBreakpointCalculator,
    }
    i18n?: {
        catalog?: { [language: string]: any },
    }
}

export interface Props extends React.PropsWithChildren<{}> {
    options: AppOptions
}

interface State {
    hasError?: boolean
    language: string;
}

declare var window: any;

export class App extends CoreComponent<Props, State> {

    public state: State = {
        language: "",
    }

    public constructor(props: Props, context: any) {
        super(props, context)

        // Build state using props
        this.state = {} as any

        // Set i18n catalog
        if (props.options.i18n && props.options.i18n.catalog) {
            for (const languageKey in props.options.i18n.catalog) {
                if (props.options.i18n.catalog.hasOwnProperty(languageKey)) {
                    const catalog = props.options.i18n.catalog[languageKey]
                    this.fw.i18n.loadCatalog(catalog, true, languageKey)
                }
            }

            // In this case, try to load the language right now
            const language = this.getLanguageFromUrl() || this.getSavedLanguage()

            // Activate
            this.fw.i18n.setCurrentLanguage(language)

            // Update MomentJS
            Moment.globalLocale = language // for react-moment
            moment.locale(language) // for moment

            // Save
            this.state.language = language

            // Force url
            // window.location.replace(`/${this.fw.i18n.getCurrentLanguagePublic()}`)
        }
    }

    public async componentDidMount() {
        if (!this.state.language) {
            // Sets the current language
            let language = this.getLanguageFromUrl() || this.getSavedLanguage()
            await this.fw.i18n.setCurrentLanguage(language);

            // Get language from extension again
            language = this.fw.i18n.getCurrentLanguage()

            // Update MomentJS
            Moment.globalLocale = language // for react-moment
            moment.locale(language) // for moment

            // Listen for changes
            this.fw.i18n.onLanguageChange(() => {
                // Reload the page
                window.location.href = `/${this.fw.i18n.getCurrentLanguagePublic()}`
            });

            // This is a trick
            // MomentJS needs some time to evaluate itself the new language
            // So we timeout the update so it has time to do it so
            this.fw.dom.setTimeout(() => this.setState((state) => ({ ...state, language })), 0)
        }
    }

    public componentDidCatch(error: Error, info: React.ErrorInfo) {
        this.setState({
            hasError: true,
        })
        this.fw.events.publish<CoreEvents.AppError>(CoreEvents.AppError, {
            message: error.message,
            exception: error,
            stackTrace: info,
        })
    }

    public render() {
        const { hasError, language } = this.state;

        if (hasError) {
            return this.props.options.errorComponent || (
                <div style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    Ooops, your application stopped working :(
                </div>
            )
        }

        if (!language) {
            return null;
        }

        return (
            <WindowSizeProvider
                fallback={{ width: 0, height: 0 }}
                breakpoint={this.props.options.breakpoint && this.props.options.breakpoint.calculator}
            >
                <I18nProvider>
                    <Router key={language}>
                        {this.props.children || <RouterOutlet />}
                    </Router>
                </I18nProvider>
            </WindowSizeProvider>
        );
    }

    //#region Private methods

    /**
     * Gets the default language to load
     */
    private getSavedLanguage() {
        return this.fw.i18n.getCurrentLanguage() || this.fw.i18n.getSavedLanguage() || this.framework.config.locale.default;
    }

    /**
     * Gets the language from the URL
     * This is an Hack for now to handle the loading sequence
     * Language is present in the first sub path
     */
    private getLanguageFromUrl(): string | undefined {
        const paths = (window.location.pathname as string).split("/").filter((p) => p)
        const language: string = paths.shift() || ""
        if (language && this.framework.config.locale.available.indexOf(language) >= 0) {
            return language
        }
    }

    //#endregion
}
