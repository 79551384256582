import React from "react";
import { Component } from "@7egend/web.core/lib/base/component"
import styles from "./spinnerDots.module.scss";

export class SpinnerDots extends Component {
    public render() {
        return (
            <div className={styles.container}>
                <span className={styles.dot}></span>
                <span className={styles.dot}></span>
                <span className={styles.dot}></span>
            </div>
        );
    }
}
