import {
  useEffect,
  useState,
  useRef,
  useCallback,
  RefObject,
  Dispatch,
  SetStateAction,
  ComponentState
} from "react";

type Ref = Element | null;
type SetRef = Dispatch<SetStateAction<Ref>>;
type ExternalState = ComponentState[];

interface OnIntersectCallback {
  (entry: IntersectionObserverEntry, observer: IntersectionObserver): void;
}

interface UseInViewOptions extends IntersectionObserverInit {
  target?: RefObject<Ref>;
  onEnter?: OnIntersectCallback;
  onLeave?: OnIntersectCallback;
  unobserveOnEnter?: boolean;
}

interface UseInViewState {
  isIntersecting: boolean;
  entry: IntersectionObserverEntry | null;
}

export type useInViewReturn = [
  SetRef,
  UseInViewState["isIntersecting"],
  UseInViewState["entry"],
  IntersectionObserver | null
];

interface UseObserver {
  (
    ref: Ref,
    callback: IntersectionObserverCallback,
    options?: IntersectionObserverInit,
    externalState?: ExternalState
  ): IntersectionObserver | null;
}

interface UseInViewEffect {
  (
    callback: IntersectionObserverCallback,
    options?: IntersectionObserverInit,
    externalState?: ExternalState
  ): SetRef;
}

interface UseInView {
  (options?: UseInViewOptions, externalState?: ExternalState): useInViewReturn;
}

const useObserver: UseObserver = (
  ref,
  callback,
  options: IntersectionObserverInit = {},
  externalState: unknown[] = []
) => {
  const Observer = useRef<IntersectionObserver | null>(null);
  const dependencies = [ref, ...externalState];
  const onIntersect = useCallback(callback, dependencies);

  useEffect(() => {
    if (!ref) return;
    if (Observer.current) Observer.current.unobserve(ref);
    Observer.current = new IntersectionObserver(onIntersect, options);

    const { current: currentObserver } = Observer;

    currentObserver.observe(ref);
    return () => currentObserver.unobserve(ref);
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps

  return Observer.current;
};

export const useInViewEffect: UseInViewEffect = (
  callback,
  options = {},
  externalState = []
) => {
  const [ref, setRef] = useState<Ref>(null);

  useObserver(ref, callback, options, externalState);

  return setRef;
};

/**
 * Hook that allows you to detect if an element is visible on the viewport or not
 *
 * ## How to use inside a function component:
 * ```javascript
 * import { useInView } from "@7egend/web.hooks";
 *
 * const Component = () => {
 *  const [inViewRef, inView] = useInView({
 *    threshold: 1,
 *    onEnter: onViewEnter,
 *    onLeave: onViewLeave,
 *    unobserveOnEnter: false
 *  });
 *
 *  const onViewEnter = React.useCallback(() => {
 *    console.log('Element entered view');
 *  }, []);
 *
 *  const onViewLeave = React.useCallback(() => {
 *    console.log('Element leaving view');
 *  }, []);
 *
 *  return <p ref={inViewRef}>Is this in view: {inView}</p>
 * }
 * ```
 */
export const useInView: UseInView = (options, externalState = []) => {
  const { ...ops } = options;

  const {
    root = null,
    rootMargin = "0px 0px 0px 0px",
    threshold = 0,
    target,
    onEnter,
    onLeave,
    unobserveOnEnter
  } = ops;

  const [ref, setRef] = useState<Ref>(null);
  const [state, setState] = useState<UseInViewState>({
    isIntersecting: false,
    entry: null
  });

  const callback: IntersectionObserverCallback = ([entry], observer): void => {
    if (!ref || !entry || !observer) return;

    const { isIntersecting } = entry;

    setState({
      isIntersecting,
      entry
    });

    if (isIntersecting) {
      onEnter && onEnter(entry, observer);
      if (unobserveOnEnter) observer.unobserve(ref);
    } else {
      onLeave && onLeave(entry, observer);
    }
  };

  useEffect(() => {
    if (!target) return;
    setRef(target.current);
  }, [target]);

  const observer = useObserver(
    ref,
    callback,
    { root, rootMargin, threshold },
    externalState
  );

  return [setRef, state.isIntersecting, state.entry, observer];
};

export default useInView;
