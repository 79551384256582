
export const PageView = "analytics.PageView"
export interface PageView {
    title?: string
    location?: string
    path?: string
    dimensions?: {[key: string]: string}
    metrics?: {[key: string]: string}
}

export const Event = "analytics.Event"
export interface Event {
    category?: string
    action: string
    label?: string
    value?: any
    nonInteraction?: boolean
    params?: {[key: string]: any}
}

export const Search = "analytics.Search"
export interface Search {
    search_term: string
}
