import { Store } from "@7egend/web.core";
import { Generic } from "@7egend/web.core/lib/base/generic";
import { State } from "../state";
import {
    GetMenuErrorAction,
    GetMenuSuccessAction,
    GetMenuStartAction,
} from "./types";

import { Menu, MenuGetAllInput, MenuGetAllOutput } from "../../dlos/menu";

//#region Get session
const getMenuStart = (): GetMenuStartAction => ({ type: "@@core.cms/GET_MENU_START" });
const getMenuSuccess = (payload: Menu[]): GetMenuSuccessAction => ({ type: "@@core.cms/GET_MENU_SUCCESS", payload });
const getMenuError = (payload: any): GetMenuErrorAction => ({ type: "@@core.cms/GET_MENU_ERROR", payload });

/**
 * Gets a session from an external API
 */
export function getMenu() {
    return async (dispatch: Store.Dispatch, getState: (() => State)) => {
        dispatch(getMenuStart());
        const generic = new Generic();
        const request = new MenuGetAllInput();

        request.body = {
            language: generic.fw.i18n.getCurrentLanguage(),
        }

        try {
            const response = await generic.framework.dlo.call(request) as MenuGetAllOutput;
            dispatch(getMenuSuccess(response.body));
        } catch (error) {
            dispatch(getMenuError(error));
        }
    }
}

//#endregion
