import { BaseModel } from "@7egend/web.core.dlos";
import { FormInput } from "./formInput";
import { DloInput, DloOutput } from "@7egend/web.core/lib/extensions/dlo";

export interface FormTemplate extends BaseModel {
    secret?: string;
    title?: string;
    subtitle?: string;
    description: string;
    inputs: FormInput[];
}

export interface FormAnswer {
    input: string,
    value: string
}

export class FormGetInput extends DloInput {
    public uri = "forms-forms/${secret}?fields=${fields}"
    public fields = "id,secret,title,subtitle,description,type(code),groups(code),inputs(id,label,placeholder,mandatory,type(code),title,description,options(value,position,option(value)),rules(value,type(code))),icon(id,image)"
    public secret?: string
}

export class FormGetOutput extends DloOutput<FormTemplate> { }

export class FormSubmitInput extends DloInput<{
    answers: FormAnswer[]
    email?: string,
}> {
    public method = "POST";
    public uri = "forms-forms/${secret}/answers";
    public secret?: string
}

export class FormSubmitOutput extends DloOutput<any> {}
