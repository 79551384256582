import React from "react";
import { Component } from "@7egend/web.core/lib/base/component";
import { Store } from "@7egend/web.core";
import { actions } from "../../services/forms";
import { FormAnswer, FormSubmitOutput } from "../../dlos/formTemplate";
/**
 * Own component props
 */
export interface OwnProps {
    // nothing
}

/**
 * Store props
 */
export interface StoreProps {
}

/**
 * Store dispatch props
 */
export interface StoreDispatchProps {
    submitForm: (secret: string, answers: FormAnswer[], email?: string) => Promise<FormSubmitOutput>;
}

/**
 * Props that are going to be injected in the target component
 */
export interface WithFormProps extends StoreProps, StoreDispatchProps {
    updateAnswers: (formAnswers: FormAnswer[], nameInput: string, valueInput: string) => FormAnswer[];
}

/**
 * HOC that provides access to the session
 * @param WrappedComponent React component
 */
export const WithForm: <P extends object>(WrappedComponent: React.ComponentType<P & WithFormProps>) => React.ComponentType<P> = (WrappedComponent) => {

    //#region Store mapping

    /**
     * Map store state to component props
     */
    function mapStateToProps(state: any, ownProps: OwnProps): StoreProps {
        return {}
    }

    /**
     * Map store dispatch actions to props
     */
    function mapDispatchToProps(dispatch: Store.ThunkDispatch<any, void, Store.Action>, ownProps: OwnProps): StoreDispatchProps {
        return {
            submitForm: (secret: string, answers: FormAnswer[], email?: string) => dispatch(actions.submitForm(secret, answers, email)),
        }
    }

    //#endregion

    class WithFormComponent extends Component<WithFormProps> {

        public render() {
            return (
                <WrappedComponent
                    {...this.props as any}
                    updateAnswers={this.updateAnswers}
                />
            )
        }

        /**
         * function to update values of form added dynamically
         */
        private updateAnswers = (formAnswers: FormAnswer[], nameInput: string, valueInput: string): FormAnswer[] => {
            const answers: FormAnswer[] = [];

            formAnswers!.map((item) => {

                const name = item.input;
                let value = item.value;

                if (name === nameInput) {
                    value = valueInput;
                }

                answers.push({
                    input: name,
                    value,
                });
            });

            return answers;
        }
    }

    return Store.connect(mapStateToProps, mapDispatchToProps)(WithFormComponent) as any;
}
