import React from "react";

export type useToggleReturn = [boolean, (override?: boolean) => void];

/**
 * Hook that abstracts a simple true/false toggle
 *
 * ## How to use inside a function component:
 * ```javascript
 * import { useToggle } from "@7egend/web.hooks";
 *
 * const Component = () => {
 *  const [ toggled, toggle ] = useToggle();
 *  return <button onClick={() => toggle()}>Is toggled: { toggled.toString() }</button>
 * }
 * ```
 *
 * ## You can override the toggle value if you need to:
 *
 * ```javascript
 * const Component = () => {
 *  const [ toggled, toggle ] = useToggle();
 *  return (
 *    <>
 *      <button onClick={() => toggle(true)}>Will always set the toggle value to true</button>
 *      <button onClick={() => toggle(false)}>Will always set the toggle value to false</button>
 *    </>
 *  )
 * }
 * ```
 */
export const useToggle = (initialState = false): useToggleReturn => {
  const [toggled, setToggle] = React.useState<boolean>(initialState);
  const toggle = React.useCallback(
    (toggleOverride?: boolean) =>
      setToggle(prevToggle =>
        toggleOverride !== undefined ? toggleOverride : !prevToggle
      ),
    []
  );
  return [toggled, toggle];
};

export default useToggle;
