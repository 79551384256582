import { Framework, Generic } from "../../base";

/**
 * Interface for DLO declaration.
 * This interface provides the basic information for the Dlo Extension to work.
 */
export interface DloInputInterface<T> {
    /**
     * Locale for request
     */
    locale?: string;

    /**
     * Uri
     * Accepts replacing pattern `${property}`, where property must be part of the input
     */
    uri: string;

    /** Method */
    method: string | "GET" | "POST" | "PUT" | "DELETE";

    /** Data */
    body?: T;

    /** Authentication flag */
    authentication?: boolean;

    /** Headers */
    headers?: { [key: string]: string }

    /**
     * Transforms the input response before sending
     * @param input Input request
     * @returns A valid input request
     */
    transform?: (input: DloInput<T>) => DloInput<T>;

    /**
     * API
     * If a connection with a different API is required
     * The value of this key must be the same as that defined in the config.json file
     */
    config?: string;

    /** Framework */
    fw: Framework;
}

export class DloInput<T = any> implements DloInputInterface<T>  {
    public locale?: string
    public uri = null as any;
    public method = "GET";
    public body?: T;
    public authentication?: boolean;
    public headers?: { [key: string]: string }
    public transform?: (input: DloInput<T>) => DloInput<T>;
    public config?: string;
    public fw = new Generic().framework;
}
