import React from "react";
import { PureComponent } from "@7egend/web.core/lib/base/component"

export interface InputProps {
    className?: string

    /** type  */
    type: string;

    /** name */
    name: string;

    /** value */
    value?: string | number;

    /** required text */
    required?: boolean;

    /** info text */
    note?: string;

    /** min value */
    min?: string

    /** max value */
    max?: string

    /** is disabled? */
    disabled?: boolean

    /** minLength value */
    minLength?: string | number

    /** maxLength value */
    maxLength?: string | number

    /** regex pattern */
    pattern?: string

    /** on change fn */
    onChange: (value: string, name: string, valid: boolean) => void

    /** on key down */
    onKeyDown?: (key: string) => void

    /** placeholder */
    placeholder?: string

    /** attribute for accessibility */
    ariaLabel?: string

    /** autofocus */
    autoFocus?: boolean

    /** on focus fn */
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;

    /** on blur fn */
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

/**
 * #Input Component
 * This component represents a html input tag
 */
export class Input extends PureComponent<InputProps> {
    public inputRef: React.RefObject<any> = React.createRef();

    public render() {
        return (
            <input
                placeholder={this.props.placeholder || " "}
                type={this.props.type}
                name={this.props.name}
                pattern={this.props.pattern}
                min={this.props.min}
                max={this.props.max}
                minLength={this.props.minLength as number}
                maxLength={this.props.maxLength as number}
                onChange={this.onChange}
                onKeyDown={this.onKeyDown}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                required={this.props.required}
                id={this.props.name}
                value={this.props.value}
                disabled={this.props.disabled === true}
                ref={this.inputRef}
                className={this.props.className}
                autoFocus={this.props.autoFocus}
            />
        );
    }

    /**
     * Enables focus on the input
     * Can be accessed with reference to the input:
     * ex: this.inputRef.current.focus();
     */
    public focus = () => {
        this.inputRef.current.focus();
    }

    private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = (e.target as any).value
        const valid: boolean = (e.target as any).validity.valid

        this.props.onChange(value, this.props.name, valid)
    }

    private onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(e.key);
        }
    }

    private onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        if (this.props.onFocus) {
            this.props.onFocus(e);
        }
    }

    private onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (this.props.onBlur) {
            this.props.onBlur(e);
        }
    }
}

export const InputComponent = Input
