import React from "react";
import qs, { IParseOptions } from "qs";

export interface useQueryReturn<T = string | number | boolean> {
  /** Query params parsed object */
  [key: string]: T | T[] | null | undefined;
}

const defaultOptions: IParseOptions = {
  ignoreQueryPrefix: true
};

/**
 * Hook that parses a browser address search value and generates a query params object.
 * Internally it uses the "qs" library (https://github.com/ljharb/qs) so you can pass
 * a parsing options configuration object as the second argument for the hook call.
 *
 * ## How to use inside a function component:
 * ```javascript
 * import { useQueryParams } from "@7egend/web.hooks";
 *
 * const Component = (props) => {
 *  const queryParams = useQueryParams("?a=b&c=d");
 *  return <div>{JSON.stringify(queryParams)}</div>
 * }
 * ```
 */
export const useQueryParams = (
  query: string,
  parseOptions: IParseOptions = defaultOptions
): useQueryReturn => {
  const queryParams = React.useMemo(() => qs.parse(query, parseOptions), [
    query,
    parseOptions
  ]);
  return queryParams;
};

export default useQueryParams;
