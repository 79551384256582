import React, { ReactHTML, Component, PureComponent } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Component as GroundComponentInterface } from "@7egend/web.ground/lib/domain/components/component";
import { Framework } from "./framework";
import { Generic } from "./generic";

//#region Exports
export { React, ReactDOM, ReactHTML }
export { Component, PureComponent }
export { Link }
//#endregion

export class CoreComponent<Props = {}, State = {}> extends Component<Props, State> implements Component, GroundComponentInterface {

    public framework: Framework;
    public fw: Framework;

    constructor(options: Readonly<Props>, context?: any) {
        super(options, context);

        // Get a generic object and build it here
        const generic = new Generic();
        this.framework = this.fw = generic.framework as Framework;

        // Build internal references
        // const diMetadataKey = Reflect.getMetadata("di:key", this.constructor);
        // if (diMetadataKey) {
        //     this.fw.di.bindValue(diMetadataKey, this.constructor);
        // }
    }

    /**
     * Dispatches a state update that will only resolve when completed.
     * Allows to wait for a Promise.
     * Same as setState(..., callback).
     * @param state State change
     */
    public setStateAsync<K extends keyof State>(state: (Pick<State, K> | State | null)) {
        return new Promise((resolve) => {
          this.setState(state, resolve)
        })
    }

}

export class CorePureComponent<Props = {}, State = {}> extends PureComponent<Props, State> implements PureComponent, GroundComponentInterface {

    public framework: Framework;
    public fw: Framework;

    constructor(options: Readonly<Props>, context?: any) {
        super(options, context);

        // Get a generic object and build it here
        const generic = new Generic();
        this.framework = this.fw = generic.framework as Framework;

        // Build internal references
        // const diMetadataKey = Reflect.getMetadata("di:key", this.constructor);
        // if (diMetadataKey) {
        //     this.fw.di.bindValue(diMetadataKey, this.constructor);
        // }
    }
}
