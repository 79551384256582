import React from "react";
import { CoreComponent } from "../../base/component";
import { SizeContext, SizeContextContent } from "./context";
import { Size, Breakpoint } from "./types";
import { debounce } from "../../utils";

//#region Exports
export type WindowSizeBreakpointCalculator = (size: Size) => Breakpoint;
export { Size, Breakpoint }
//#endregion

export interface WindowSizeProviderProps {
    /** Fallback dimensions when not available */
    fallback?: Size;
    /** Breakpoint calculator method */
    breakpoint?: WindowSizeBreakpointCalculator;
    /** Delay or debounce interval in ms, default 100 */
    delay?: number;
}

export class WindowSizeProvider extends CoreComponent<WindowSizeProviderProps, SizeContextContent> {

    public state = {
        size: {
            width: 0,
            height: 0,
        },
        breakpoint: Breakpoint.Desktop,
    }

    //#region Private methods

    /**
     * Dispatch a resize event.
     * This calculates the new width and height and only
     * dispatches the event if they have changed
     */
    private dispatchSizes = debounce(() => {
        // Calculate the size
        const sizes = this.getWindowSize(
            this.props.fallback!.width,
            this.props.fallback!.height,
        );

        // Dispatch an update only when the size changes
        if (sizes.width !== this.state.size.width || sizes.height !== this.state.size.height) {
            const size = {
                width: sizes.width,
                height: sizes.height,
            };

            const breakpoint = this.props.breakpoint ? this.props.breakpoint(size) : this.state.breakpoint;

            this.setState({
                size,
                breakpoint,
            });
        }
    }, this.props.delay || 100);

    public componentDidMount() {
        this.fw.dom.getGlobal().addEventListener("resize", this.dispatchSizes);
        this.dispatchSizes();
    }

    public componentWillUnmount() {
        this.fw.dom.getGlobal().removeEventListener("resize", this.dispatchSizes);
    }

    public render() {
        return (
            <SizeContext.Provider value={this.state}>
                {this.props.children}
            </SizeContext.Provider>
        );
    }

    /**
     * Gets the current window size
     * Use a fallback when needed or if DOM is not available
     * @param fallbackWidth Default width
     * @param fallbackHeight Default height
     */
    private getWindowSize(fallbackWidth?: number, fallbackHeight?: number) {
        const window = this.fw.dom.getGlobal()
        const canUseDOM = typeof window !== "undefined"

        if (canUseDOM) {
            // Use BoundingRect to consider the scroll element
            const bounds = window.document.body.getBoundingClientRect();
            return {
                width: bounds.width,
                height: window.innerHeight,
                canUseDOM,
            }
        }

        return {
            width: fallbackWidth,
            height: fallbackHeight,
            canUseDOM,
        }
    }
    //#endregion

}
