//#region Models

// References
// https://developers.google.com/tag-manager/devguide
// (old) https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce
// (old) https://developers.google.com/gtagjs/reference/event

export interface ImpressionData {
    /** Impression items */
    items: ImpressionItemData[]
}

export interface ImpressionItemData {
    /** Unique ID/SKU for the item */
    id: string
    /** Item name */
    name: string
    /** Brand of the item */
    brand?: string
    /** Item category */
    category?: string
    /** Item variant */
    variant?: string
    /** Purchase price of the item */
    price?: number
    /** The list in which the item was presented to the user */
    list?: string
    /** The position of the item in the list */
    position?: number
}

export interface SelectData extends ImpressionData {
    content_type: string
    /** The list in which the item was presented to the user */
    list?: string
}

export interface BasketData {
    /** Basket items */
    items: ProductData[]
}

export interface ProductData extends ImpressionItemData {
    /** Item quantity */
    quantity?: number
    /** Coupon code for a purchasable item */
    coupon?: string
}

export interface PromotionData {
    /** Promotion ID */
    id: string
    /** Name of promotion */
    name: string
    /** The name of a creative used */
    creative?: string
    /** The name of the creative slot */
    position?: string
}

export interface Promotions {
    promotions: PromotionData[]
}

export interface CheckoutData {
    /** The array containing the associated products */
    items?: ProductData[]
    /** The step (a number) in the checkout process */
    step?: number
    /** Coupon code for a purchasable item */
    coupon?: string
}

export interface CheckoutOptionData {
    /** The step (a number) in the checkout process */
    step: number
    /** Checkout option (i.e. selected payment method) */
    option: string
    /** Value associated with the event */
    value: string
}

export interface CheckoutPurchaseData extends CheckoutData {
    /** Unique ID for the transaction. */
    transaction_id: string
    /** The store or affiliation from which this transaction occurred */
    affiliation?: string
    /** Currency */
    currency: string
    /** Tax amount */
    tax?: number
    /** Shipping cost */
    shipping: number
    /** Total value purchased */
    value: number
}

export type RefundData = {
    /** Unique ID for the transaction. */
    transaction_id: string,
} | CheckoutPurchaseData

//#endregion

export const ItemView = "analytics.item_view"
export type ItemView = ImpressionData

export const ItemSelect = "analytics.item_select"
export type ItemSelect = SelectData

export const ItemListView = "analytics.item_list_view"
export type ItemListView = ImpressionData

export const BasketAddItems = "analytics.basket_add_items"
export type BasketAddItems = BasketData

export const BasketRemoveItems = "analytics.basket_remove_items"
export type BasketRemoveItems = BasketData

export const CheckoutStart = "analytics.checkout_start"
export type CheckoutStart = CheckoutData

export const CheckoutUpdate = "analytics.checkout_update"
export type CheckoutUpdate = CheckoutData

export const CheckoutSetOption = "analytics.checkout_set_option"
export type CheckoutSetOption = CheckoutOptionData

export const CheckoutPurchase = "analytics.checkout_purchase"
export type CheckoutPurchase = CheckoutPurchaseData

export const Refund = "analytics.refund"
export type Refund = RefundData

export const Promotion = "analytics.view_promotion"
export type Promotion = PromotionData
