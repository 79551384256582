import React from "react";

export interface useHoverReturn {
  /** If the element is hovered or not */
  hovered: boolean;
  /** The two mouse events that will be bound to an element */
  hoverBind: {
    onMouseEnter: () => void;
    onMouseLeave: () => void;
  };
}

/**
 * Hook that abstracts a simple mouse enter/leave
 *
 * ## How to use inside a function component:
 * ```javascript
 * import { useHover } from "@7egend/web.hooks";
 *
 * const Component = () => {
 *  const { hovered, hoverBind } = useHover();
 *
 *  return <button {...hoverBind}>Is hovered: { hovered.toString() }</button>
 * }
 * ```
 */
export const useHover = (): useHoverReturn => {
  const [hovered, set] = React.useState<boolean>(false);
  const onEnter = React.useCallback(() => set(true), []);
  const onLeave = React.useCallback(() => set(false), []);

  return {
    hovered,
    hoverBind: {
      onMouseEnter: onEnter,
      onMouseLeave: onLeave
    }
  };
};

export default useHover;
