import { Store } from "@7egend/web.core";
import { SectionsContainersActions, SectionsContainersState } from "./types";
import { Container } from "../../dlos/container"

const initialState: SectionsContainersState = {}

const root: Store.Reducer<SectionsContainersState> = (state: SectionsContainersState = initialState, action: any) => {
    switch ((action as SectionsContainersActions).type) {
        case "@@core.cms/GET_SECTION_CONTAINERS_START":
            return state
        case "@@core.cms/GET_SECTION_CONTAINERS_SUCCESS":
            return {...state, [action.payload.slug]: (action.payload.payload.map((container: Container) => container.reference || container.slug)) }
        case "@@core.cms/GET_SECTION_CONTAINERS_ERROR":
            return state
        default:
            return state;
    }
}

export { root }
