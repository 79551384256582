import { Store } from "@7egend/web.core";
import { Generic } from "@7egend/web.core/lib/base/generic";
import { State } from "../state";
import {
    GetSectionContainersStartAction,
    GetSectionContainersSuccessAction,
    GetSectionContainersErrorAction,
} from "./types";
import { actions as containerActions } from "../containers"
import { SectionGetContainersInput, SectionGetContainersOutput } from "../../dlos/section";
import { Container } from "../../dlos/container";

//#region GetSectionContainers

const getSectionContainersStart = (): GetSectionContainersStartAction => ({ type: "@@core.cms/GET_SECTION_CONTAINERS_START" });
const getSectionContainersSuccess = (slug: string, payload: Container[]): GetSectionContainersSuccessAction => ({ type: "@@core.cms/GET_SECTION_CONTAINERS_SUCCESS", payload: {slug, payload} });
const getSectionContainersError = (payload: any): GetSectionContainersErrorAction => ({ type: "@@core.cms/GET_SECTION_CONTAINERS_ERROR", payload });

/**
 * GetSectionContainers
 */
export function getSectionContainers(slug: string) {
    return async (dispatch: Store.Dispatch, getState: (() => State)) => {
        dispatch(getSectionContainersStart());
        const generic = new Generic();
        const request = new SectionGetContainersInput()
        request.body = {
            slug,
        }
        try {
            const response = await generic.framework.dlo.call(request) as SectionGetContainersOutput
            dispatch(getSectionContainersSuccess(slug, response.body))
            // Also dispatch this update to the Containers
            dispatch(containerActions.getContainerSuccess(response.body))
        } catch (error) {
            dispatch(getSectionContainersError(error));
        }
    }
}

//#endregion
