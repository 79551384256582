import { useState, useEffect, useCallback } from "react";
import debounce from "lodash.debounce";

export interface useWindowSizeReturn {
  width?: number;
  height?: number;
}

export const useWindowSize = () => {
  const getSize = useCallback((): useWindowSizeReturn => {
    return {
      width: window ? window.innerWidth : undefined,
      height: window ? window.innerHeight : undefined
    };
  }, []);

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!window) {
      return;
    }

    const handleResize = debounce(() => {
      setWindowSize(getSize());
    }, 500);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [getSize]);

  return windowSize;
};

export default useWindowSize;
