/**
 * Interface for DLO declaration.
 * This interface provides basic information for DLO responses.
 */
export interface DloOutputInterface {
    status: number;
    headers: {[key: string]: string};
    body: any;
}

export class DloOutput<T = any> implements DloOutputInterface {
    public status = null as any;
    public headers = {};
    public body: T = null as any;
}
