import { BaseModel } from "@7egend/web.core.dlos";
import { DloInput, DloOutput } from "@7egend/web.core/lib/extensions/dlo";
import { Medium } from "@7egend/web.core.media/lib/dlos/medium";
import { Language } from "@7egend/web.core.geo/lib/dlos/language";
import { Page } from "./page";

export interface Menu extends BaseModel {
    position?: string
    language?: Language
    name: string
    published?: boolean
    editable?: boolean
    pages?: Page[]
    path?: string
    url?: string
    icon?: Medium
    parent?: Menu
    menus?: Menu[]
    cb?: () => void
}

export class MenuGetAllInput extends DloInput<{
    language: string,
}> {
    public uri = "cms-menus?fields=${fields}"
    public fields = "id,position,name,path,url,published,icon(uuid,mime_type,medium_type,image,thumb,url),parent(id,name),pages(id,internal_name)"
}

export class MenuGetAllOutput extends DloOutput<Menu[]> { }
