import { CorePureComponent } from "@7egend/web.core/lib/base/component"
import { NOOP } from "../../utils/noop"
import { Events, EcommerceEvents, OnBeforeEventHandler, AnalyticsPlatform } from "../../base"

export interface Props {
    /** Account ID */
    id: string
    /** Enable flag */
    enable?: boolean
    /** Before Event event */
    beforeEvent?: OnBeforeEventHandler
}

interface EventConfiguration {
    key: string
    event?: string
    transform?: (options: any) => any
}

const EVENTS: EventConfiguration[] = [
    {
        key: Events.Event,
    },
    {
        key: Events.PageView,
        event: "page_view",
    },
    {
        key: Events.Search,
        event: "search",
    },
]

const EVENTS_ECOMMERCE: EventConfiguration[] = [
    {
        key: EcommerceEvents.ItemView,
        event: "view_item",
        transform: (options: EcommerceEvents.ItemView) => {
            return {
                detail: {
                    products: options.items,
                },
            }
        },
    },
    {
        key: EcommerceEvents.ItemListView,
        event: "view_item_list",
        transform: (options: EcommerceEvents.ItemListView) => {
            return {
                impressions: options.items,
            }
        },
    },
    {
        key: EcommerceEvents.ItemSelect,
        event: "select_content",
        transform: (options: EcommerceEvents.ItemSelect) => {
            return {
                click: {
                    products: options.items,
                    actionField: {
                        list: options.list || options.items[0].list,
                    },
                },
            }
        },
    },
    {
        key: EcommerceEvents.Promotion,
        event: "select_content",
        transform: (options: EcommerceEvents.Promotion) => {
            return {
                promoView: {
                    promotions: [{ ...options }],
                },
            }
        },
    },
    {
        key: EcommerceEvents.BasketAddItems,
        event: "add_to_cart",
        transform: (options: EcommerceEvents.BasketAddItems) => {
            return {
                add: {
                    products: options.items,
                },
            }
        },
    },
    {
        key: EcommerceEvents.BasketRemoveItems,
        event: "remove_from_cart",
        transform: (options: EcommerceEvents.BasketRemoveItems) => {
            return {
                remove: {
                    products: options.items,
                },
            }
        },
    },
    {
        key: EcommerceEvents.CheckoutStart,
        event: "begin_checkout",
        transform: (options: EcommerceEvents.CheckoutStart) => {
            return {
                checkout: {
                    products: options.items,
                    actionField: {
                        step: options.step || 1,
                    },
                },
            }
        },
    },
    {
        key: EcommerceEvents.CheckoutUpdate,
        event: "checkout_progress",
        transform: (options: EcommerceEvents.CheckoutUpdate) => {
            return {
                checkout: {
                    products: options.items,
                    actionField: {
                        step: options.step,
                    },
                },
            }
        },
    },
    {
        key: EcommerceEvents.CheckoutSetOption,
        event: "set_checkout_option",
        transform: (options: EcommerceEvents.CheckoutSetOption) => {
            return {
                checkout_option: {
                    actionField: {
                        step: options.step,
                        option: `${options.option} ${options.value}`,
                    },
                },
            }
        },
    },
    {
        key: EcommerceEvents.CheckoutPurchase,
        event: "purchase",
        transform: (options: EcommerceEvents.CheckoutPurchase) => {
            return {
                currencyCode: options.currency,
                purchase: {
                    products: options.items,
                    actionField: {
                        ...options,
                        id: options.transaction_id,
                        affiliation: options.affiliation,
                        revenue: options.value,
                        tax: options.tax,
                        shipping: options.shipping,
                        coupon: options.coupon,
                    },
                },
            }
        },
    },
    {
        key: EcommerceEvents.Refund,
        event: "refund",
        transform: (options: EcommerceEvents.Refund) => {
            return {
                refund: {
                    actionField: {
                        id: options.transaction_id,
                    },
                },
            }
        },
    },
]

export class GoogleTagManagerProvider extends CorePureComponent<Props> {

    public static defaultProps: Partial<Props> = {
        enable: true,
    }

    /** List of subscribers */
    private subscribers: Array<() => void> = []

    /** Local API */
    private api = NOOP

    public constructor(props: any, context: any) {
        super(props, context)

        this.setupDataLayer()
    }

    public componentDidMount() {
        this.setup()
        this.setupHandlers()
    }

    public componentDidUpdate(prevProps: Props) {
        if (prevProps.enable !== this.props.enable && this.props.enable) {
            this.setup()
        }
    }

    public componentWillUnmount() {
        this.subscribers.forEach((subscriber) => subscriber())
    }

    public render() {
        return this.props.children
    }

    //#region Private methods

    private setup() {
        const { id, enable } = this.props

        // Prevent loading if no Id was given
        if (!id) {
            return
        }

        if (!enable) {
            return
        }

        // Do not run if SSR
        if (!this.fw.dom.getGlobal()) {
            throw new Error("You can not use Google without DOM")
        }

        // Load GTM script
        // https://developers.google.com/tag-manager/quickstart
        // tslint:disable
        (function (w, d, s, l, i) {
            w[l] = w[l] || []; w[l].push({
                "gtm.start":
                    new Date().getTime(), "event": "gtm.js"
            }); let f = d.getElementsByTagName(s)[0],
                j = d.createElement(s), dl = l != "dataLayer" ? "&l=" + l : ""; j.async = true; j.src =
                    "https://www.googletagmanager.com/gtm.js?id=" + i + dl; f.parentNode.insertBefore(j, f);
        })(this.fw.dom.getGlobal(), this.fw.dom.getDocument(), "script", "dataLayer", id);
        // tslint:enable
    }

    /**
     * Setup DataLayer
     * This will wrap the gtag global method and pipe all requests to global.dataLayer
     * so that GA can handle it
     */
    private setupDataLayer(): void {
        // Create the container for gtag
        const global = this.fw.dom.getGlobal()

        // Do not run if SSR
        if (global) {
            // Setup the data layer for events
            global.dataLayer = global.dataLayer || []
            // Bind the callback function
            global.gtag = function gtag() {
                global.dataLayer.push(...arguments as any)
            }
            // Save to local var
            this.api = global.gtag
        }
    }

    private setupHandlers() {
        const { beforeEvent } = this.props

        const onEvent = (config: EventConfiguration) => (options: any) => {
            options = options || {}

            const prefix = "event"
            const event = options.event || (prefix ? `${prefix}.${(options as any).action || config.event}` : config.event)

            let data = {
                event,
                action: event,
                ...options,
            }

            if (beforeEvent) {
                data = beforeEvent(AnalyticsPlatform.GoogleTagManager, data.event, data)
            }

            if (data) {
                this.api(data)
            }
        }

        const onEventEcommerce = (config: EventConfiguration) => (options: any) => {
            const prefix = "ecommerce"
            const event = options.event || (prefix ? `${prefix}.${(options as any).action || config.event}` : config.event)

            // Transform before send
            if (config.transform) {
                options = config.transform(options)
            }

            // Wrap everything inside ecommerce property
            if (!options.ecommerce) {
                options = {
                    ecommerce: options,
                }
            }

            let data = {
                event: options.event || event,
                action: config.event,
                ...options,
            }

            if (beforeEvent) {
                data = beforeEvent(AnalyticsPlatform.GoogleTagManager, data.event, data)
            }

            if (data) {
                this.api(data)
            }
        }

        this.subscribers.push(...EVENTS.map((event) => this.fw.events.subscribe(event.key, onEvent(event))))
        this.subscribers.push(...EVENTS_ECOMMERCE.map((event) => this.fw.events.subscribe(event.key, onEventEcommerce(event))))
    }

    //#endregion

}
