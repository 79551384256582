import React from "react";
import { PureComponent, Config, Store } from "../../base";
import { App, AppOptions } from "../app/app";
import { ground, ExtensionConstructor } from "@7egend/web.ground/lib/ground";
import { DloExtension } from "../../extensions/dlo";

declare var window: any;

export interface CoreAppProps extends React.PropsWithChildren<{}> {
    /** Application config */
    config?: Config

    /** Config loader */
    configLoader?: (config?: Config) => Config

    /** Extensions */
    extensions?: { [key: string]: ExtensionConstructor }

    /** Other options */
    options: AppOptions
}

interface State {

}

/**
 * # Core App
 * CoreApp root component.
 * In order to use any Core component, you need to wrap your application with this component.
 *
 * ## How to use
 * ```jsx
 * <CoreApp
 *
 * >
 *  {...}
 * </CoreApp>
 * ```
 */
export class CoreApp extends PureComponent<CoreAppProps> {

    public state: State = {}

    constructor(props: CoreAppProps, context: any) {
        super(props, context)

        // Check configuration
        // Load configuration from props
        let config = this.props.config

        // Load from local storage if exists and merge them
        const configWindow = window && window.__config || {}
        const configLocalStorage = window && window.localStorage && window.localStorage.getItem("__config") || "{}"
        config = { ...config, ...configWindow, ...JSON.parse(configLocalStorage) }

        // Run the loader for further configuration
        if (this.props.configLoader) {
            config = this.props.configLoader(config)
        }

        // By default, include these extensions
        const extensions = this.props.extensions || {}
        extensions.dlo = extensions.dlo || DloExtension

        // Load ground
        ground.start(config!, extensions)
    }

    public render() {
        // Check if it is safe to load the app
        // There might be some parts still loading

        // For now, nothing

        return (
            <Store.Provider store={Store.store}>
                <App options={this.props.options}>
                    {this.props.children}
                </App>
            </Store.Provider>
        )
    }

}
