/// <reference types="node" />

import { Store, createStore, applyMiddleware, combineReducers, Reducer, Dispatch, Action, compose } from "redux";
import { Provider, connect } from "react-redux";
import thunk, { ThunkAction, ThunkDispatch } from "redux-thunk";
import * as utils from "./store/utils";

declare var window: any;

//#region Interfaces - Utils
export type StoreGetter<S> = () => S
//#endregion

//#region Interfaces - Data nodes

/**
 * Represents a Data Node that can be used by default
 * With these states you can:
 * - Handle not loaded state (start)
 * - Handle content is loading
 * - Handle content was loaded and is loading more data
 * - Handle error state
 */
export interface StoreDataNode<DataType, ContextType = {}, MetadataType = any, ErrorType = string> {
    /** Context for node. Used to identify that is beign saved */
    context?: ContextType
    /** Data */
    data?: DataType
    /** Metadata */
    metadata?: MetadataType,
    /** Content is loading */
    loading?: boolean
    /** Content was loaded or first run is done */
    loaded?: boolean
    /** Error content */
    error?: StoreDataNodeError<ErrorType>
    /** Last update time */
    lastUpdate?: Date
}

/**
 * Represents an Error Data Node
 */
export interface StoreDataNodeError<MessageType = string> {
    http: string
    code: string
    message: MessageType
}

//#endregion

//#region Public methods

export interface ReducerConfig {
    /**
     * Reducer key.
     * This will be the root name for this reducer.
     */
    key: string
    /**
     * Reducer instance.
     */
    reducer: Reducer
}

/**
 * Creates a new Reducer.
 * This method accepts async reducers.
 */
export const createReducer = (asyncReducers = {}) => {
    return combineReducers({
        root: (state= null) => state,
        ...asyncReducers,
    });
}

/**
 * Register a new Reducer in the store.
 */
export const registerReducer = (targetStore: Store, name: string, reducer: Reducer) => {
    (targetStore as any).async = (targetStore as any).async || {};
    (targetStore as any).async[name] = reducer;
    targetStore.replaceReducer(createReducer((targetStore as any).async));
}

/**
 * Loads and register a new Reducer in the store
 * @param config Reducer configuration
 * @param reducerLoader [deprecated]
 */
export const loadAndRegisterReducer = async (config: string | ReducerConfig | Promise<ReducerConfig>, reducerLoader?: Promise<any> | Reducer) => {
    // Make sure the store exists
    if (!store) {
        setup()
    }

    // Build up reducer config
    let reducerConfig: ReducerConfig

    // If the config is a string, need to consider all other things first
    if (typeof config === "string") {
        let reducer
        if (reducerLoader instanceof Promise) {
            reducer = await reducerLoader
        } else {
            reducer = reducerLoader
        }
        reducerConfig = {
            key: config,
            reducer: reducer.services || reducer.reducer || reducer,
        }
    } else {
        if (config instanceof Promise) {
            reducerConfig = await config
        } else {
            reducerConfig = config
        }
    }

    registerReducer(store, reducerConfig.key, reducerConfig.reducer)
}

//#endregion

//#region Instantiation

/**
 * Store object
 * This is the only store in the application
 */
let store: Store

export const setup = () => {
    if (!store) {
        // Devtools extension for dev
        const composeEnhancers = process.env.NODE_ENV !== "production" && window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

        // Create store
        store = createStore(
            // Base reducer
            createReducer(),
            // Middleware
            composeEnhancers(applyMiddleware(
                // Promise middleware
                thunk,
            )),
        );
    }
}

//#endregion

export { store, Store };
export { Dispatch, Action, Reducer };
export { Provider, connect, compose };
export { ThunkAction, ThunkDispatch };
export { utils }
