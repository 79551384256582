import React from "react";
import { Generic, Framework } from "../../base";

/**
 * Hook to expose the framework for use in function components
 * ## How to use inside a function component:
 * ```javascript
 * import { useFramework } from "@7egend/web.core";
 *
 * export default (props) => {
 *  const framework = useFramework();
 *  const window = framework.dom.getGlobal();
 *
 *  return <div>useFramework hook demo</div>
 * }
 * ```
 */
export const useFramework = <T = Framework>() => {
    const [framework] = React.useState<T>(new Generic().framework as unknown as T)
    return framework
}
