import { Store } from "@7egend/web.core";
import { Generic } from "@7egend/web.core/lib/base/generic";
import { State } from "../state";
import {
    GetContainerSuccessAction, GetContainerStartAction, GetContainerErrorAction,
} from "./types";
import { Container, ContainerGetBySlugInput, ContainerGetBySlugOutput } from "../../dlos/container";

//#region GetContainer

export const getContainerStart = (): GetContainerStartAction => ({ type: "@@core.cms/GET_CONTAINER_START" });
export const getContainerSuccess = (payload: Container[]): GetContainerSuccessAction => ({ type: "@@core.cms/GET_CONTAINER_SUCCESS", payload });
export const getContainerError = (payload: any): GetContainerErrorAction => ({ type: "@@core.cms/GET_CONTAINER_ERROR", payload });

/**
 * GetSectionContainers
 */
export function getContainer(slug: string) {
    return async (dispatch: Store.Dispatch, getState: (() => State)) => {
        dispatch(getContainerStart());
        const generic = new Generic();
        const request = new ContainerGetBySlugInput()
        request.body = {
            slug,
        }
        try {
            const response = await generic.framework.dlo.call(request) as ContainerGetBySlugOutput
            dispatch(getContainerSuccess([response.body]))
        } catch (error) {
            dispatch(getContainerError(error));
        }
    }
}

//#endregion
