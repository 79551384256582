import React from "react";
import { memoizeOne } from "@7egend/web.core/lib/utils";
import { Store, Component } from "@7egend/web.core";
import { Menu } from "../../dlos/menu";
import { MenuState, actions } from "../../services/menu";
import { State } from "../../services/state";

export interface WithMenuProps extends StoreProps, StoreDispatchProps {
    /**
     * Gets the given path from menu.
     * Fallback to global menu when none is given.
     * @param path Path to search
     * @param menu List of menus available for search. Fallback to global menus
     */
    getMenuFromPath: (path: string, menu?: Menu[]) => Menu | undefined

    /**
     * Searches for given path in menus and return its path.
     * @param path Path to search
     * @param menu List of menus available for search. Fallback to global menus
     */
    getMenuPathFromPath: (path: string, menu?: Menu[]) => Menu[]
}

export interface StoreProps {
    menu: MenuState
}

export interface StoreDispatchProps {
    /**
     * Loads the menu
     */
    getMenu: () => void;
}

/**
 * HOC that provides access to the menu
 * @param WrappedComponent React component
 */
export const withMenu: <P extends WithMenuProps>(WrappedComponent: React.ComponentType<P>) => React.ComponentType<Omit<P, keyof WithMenuProps>> = (WrappedComponent) => {

    /**
     * Map store state to component props
     */
    function mapStateToProps(state: State): StoreProps {
        return {
            menu: state.cms.menu,
        }
    }

    /**
     * Map store dispatch actions to props
     */
    function mapDispatchToProps(dispatch: Store.ThunkDispatch<any, void, Store.Action>): StoreDispatchProps {
        return {
            getMenu: () => dispatch(actions.getMenu()),
        }
    }

    class WithMenuComponent extends Component<StoreProps & StoreDispatchProps> {

        private getMenuFromPath = memoizeOne((path: string, menu?: Menu[]): Menu | undefined => {
            return this.getMenuPathFromPath(path, menu)[0]
        })

        private getMenuPathFromPath = memoizeOne((key: string, menus: Menu[] = []) => {

            const searchMenuPath = (searchMenus: Menu[] = [], path: Menu[] = []): Menu[] => {
                for (const menu of searchMenus) {

                    const itemPath = menu.path || menu.url || ""

                    // Stop case: we found the node!
                    if (itemPath === key) {
                        // We will add this to the path and return
                        return [...path, menu]
                    }

                    // Search this node again and add the current to the path
                    if (menu.menus && menu.menus.length > 0) {
                        const innerPath = searchMenuPath(menu.menus, [...path, menu])

                        if (innerPath.length > 0) {
                            return innerPath
                        }
                    }
                }

                return []
            }

            return searchMenuPath(menus)
        })

        public componentDidMount() {
            if (!this.props.menu.loaded && !this.props.menu.loading) {
                this.props.getMenu()
            }
        }

        public render() {
            return (
                <WrappedComponent
                    {...this.props as any}
                    getMenuFromPath={this.getMenuFromPath}
                    getMenuPathFromPath={this.getMenuPathFromPath}
                    menu={this.props.menu}
                />
            )
        }

    }

    return Store.connect(mapStateToProps, mapDispatchToProps)(WithMenuComponent) as any;
}
