import React from "react";

export interface useScrollPositionReturn {
  scrollX: number;
  scrollY: number;
}

/**
 * Hook to listen for window vertical scroll events.
 * Will always return `0` if no window object exists
 *
 * ## How to use inside a function component:
 * ```javascript
 * import { useScrollPosition } from "@7egend/web.hooks";
 *
 * const Component = () => {
 *  const scrollY = useScrollPosition();
 *
 *  return <p>{scrollY}</p>
 * }
 * ```
 */
export const useScrollPosition = (): useScrollPositionReturn => {
  const [scrollPosition, setScrollPosition] = React.useState<
    useScrollPositionReturn
  >({ scrollX: 0, scrollY: 0 });

  React.useEffect(() => {
    let lastPosX = 0;
    let lastPosY = 0;
    let scrolling = false;

    const handleScroll = () => {
      lastPosX = window.scrollX;
      lastPosY = window.scrollY;

      if (!scrolling) {
        window.requestAnimationFrame(() => {
          setScrollPosition({ scrollX: lastPosX, scrollY: lastPosY });
          scrolling = false;
        });

        scrolling = true;
      }
    };

    if (window) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (window) {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  });

  return { scrollX: window.scrollX, scrollY: window.scrollY };
};

export default useScrollPosition;
