import { merge } from "../../../utils/merge"

// Default types for node and leaf
type QueryQLParamValue = string
type QueryQLParamNode = [string, QueryQLParams]

/**
 * Query QLParams
 * This must be a list of fields to include
 * @example
 * // ["a", "b", ["c", ["d"]] -> a,b,c(d)
 */
export interface QueryQLParams extends Array<QueryQLParamValue | QueryQLParamNode> { }

/**
 * Parses a field
 * If it's a string node it just returns it
 * and if it's an array it calls this function
 * on it's fields
 * @param field
 */
function parseField(field: QueryQLParamValue | QueryQLParamNode): string {
    if (typeof field === "string") {
        return field
    }

    if (Array.isArray(field)) {
        const fieldName = field[0]
        const fieldValues = field[1].map((value) => parseField(value))
        return `${fieldName}(${fieldValues})`
    }

    throw new Error("Unknown node building QL query")
}

/**
 * Accepts an array with a [key, [key, fields]] shape
 * and returns a string with the output format
 * @param fields Query fields object
 */
export function buildQLQuery(fields: QueryQLParams): string {

    if (fields) {
        return fields.map((field) => parseField(field)).join()
    }

    return ""
}

/**
 * Splits given fields string into small chunks (by comma)
 * @param fields Fields
 */
function splitFields(fields: string): string[] {
    const result: string[] = []
    let item = "";
    let depth = 0;

    function push() {
        if (item) {
            result.push(item);
        }
        item = "";
    }

    // tslint:disable-next-line: no-conditional-assignment
    for (let i = 0, c; c = fields[i], i < fields.length; i++) {
        if (!depth && c === ",") {
            push();
        } else {
            item += c;
            if (c === "(") {
                depth++
            } else if (c === ")") {
                depth--;
            }
        }
    }

    push();
    return result;
}

/**
 * Converts a given fields input into object
 * @param input Fields input
 */
function convertFieldsToObject(input: string) {
    const result: {[key: string]: any} = {}

    // this still has to be splitted
    const fields = splitFields(input)

    for (const field of fields) {
        const innerStart = field.indexOf("(")
        if (innerStart === -1) {
            result[field] = true
        } else {
            // extract the field name and process again
            const name = field.slice(0, innerStart)
            const value = field.slice(innerStart + 1, -1);
            result[name] = convertFieldsToObject(value)
        }
    }

    return result
}

/**
 * Converts a given fields object into input
 * @param fields Fields object
 */
function convertObjectToFields(fields: {[key: string]: any}): string {
    // Iterate over all fields
    return Object.keys(fields).map((key) => {
        if (typeof fields[key] !== "object") {
            return key
        }
        return `${key}(${convertObjectToFields(fields[key])})`
    }).join()
}

/**
 * Merge multiple fields and/or queries.
 * Warning: this is very heavy! Use it with caution.
 * @param fields Query or fields value
 */
export function mergeQLQueries(...fields: Array<QueryQLParams | string>): string {
    // Make sure we have a string for comparison
    const fieldsParsed = fields.map((field) => {
        if (typeof field === "string") {
            return field
        } else {
            return buildQLQuery(field)
        }
    })

    // Convert everything to object
    const fieldsFinal = fieldsParsed.map((field) => convertFieldsToObject(field))
    return convertObjectToFields(merge(
        fieldsFinal[0],
        ...fieldsFinal.slice(1),
    ))
}
