import { BaseModel } from "@7egend/web.core.dlos";
import { DloInput, DloOutput } from "@7egend/web.core/lib/extensions/dlo";

export interface Category extends BaseModel {
    name: string;
    slug: string;
}

export class CategoryGetAllInput extends DloInput {
    public uri = "cms-categories?fields=${fields}"
    public fields = "id,name,slug"
}

export class CategoryGetAllOutput extends DloOutput<Category[]> {}
