import { useCallback } from "react";
import debounce from "lodash.debounce";

interface DebounceOptions {
  leading: boolean;
  trailing: boolean;
}

const defaultOptions = {
  leading: false,
  trailing: true
};

/**
 * Hook that returns a debounced function.
 *
 * ## How to use inside a function component:
 * ```javascript
 * import { useDebouncedFn } from "@7egend/web.hooks";
 *
 * const Component = () => {
 *  const [windowSize, setWindowSize] = React.useState({
 *    width: window.innerWidth,
 *    height: window.innerHeight
 *  });
 *
 *  const onResize = useDebouncedFn(() => {
 *    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
 *  }, 500);
 *
 *  React.useEffect(() => {
 *    window.addEventListener('resize', onResize);
 *    return () => window.removeEventListener('resize', onResize);
 *  })
 *
 *  return <p>Width: {windowSize.width} Height: {windowSize.height}</p>
 * }
 * ```
 */
const useDebouncedFn = (
  fn: () => void,
  wait: number = 100,
  options: DebounceOptions = defaultOptions,
  dependencies: unknown[] = []
) => {
  const debounced = debounce(fn, wait, options);
  return useCallback(debounced, dependencies);
};

export default useDebouncedFn;
