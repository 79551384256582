import React from "react";
import { CoreComponent } from "@7egend/web.core/lib/base/component"
import classNames from "classnames"
import { memoizeOne } from "@7egend/web.core/lib/utils"
import { SpinnerDots } from "../spinnerDots/spinnerDots";

import styles from "./button.module.css";

export interface ButtonProps {
    /** @deprecated text to show inside button */
    text?: string,
    /** @deprecated button classes */
    classes?: string,
    /** button classes */
    className?: string,
    /** icon to apply inside button */
    icon?: React.ComponentType<any> | React.StatelessComponent<any> | Element,
    /** if true the button icon will be on the left */
    reverseIcon?: boolean
    /** function to be triggered by click */
    action?: (props: Props) => void,
    /** set if button needs be on loading mode */
    isLoading?: boolean,
    /** loading component - spinner is used by default */
    loadingComponent?: React.ComponentType<any> | React.StatelessComponent<any> | Element
    /** set if this button is a button of type submit for forms */
    inputSubmit?: boolean,
    /** disabled */
    disabled?: boolean
    /** aria label for acessibility */
    ariaLabel?: string
    /** OnClick callback */
    onClick?: (event: React.MouseEvent, props: Props) => void
    /** OnMouseOver callback */
    onMouseOver?: (event: React.MouseEvent, props: Props) => void
    /** OnMouseLeave callback */
    onMouseLeave?: (event: React.MouseEvent, props: Props) => void
}

export interface Props extends ButtonProps { }

interface State {
    /** button mouse over event */
    hover: boolean
}

/**
 * define all default props
 */
const defaultProps = {
    text: "",
    classes: "",
    className: "",
    icon: undefined,
    reverseIcon: false,
    isLoading: false,
    inputSubmit: false,
    disabled: false,
    loadingComponent: <SpinnerDots />,
}

/**
 * # Button Component
 * This is the button component
 *
 * ## Implementation Example
 * ```HTML
 * <ButtonComponent
 *  className='orange'
 *  icon="icon-back-left">
 *  Click me!
 * </ButtonComponent>
 * ```
 *
 */

export class Button extends CoreComponent<Props, State> {

    // set default props
    public static defaultProps = defaultProps;

    public state: State = {
        hover: false,
    }

    /** function to return classes according to state of button */
    public getEffectClasses = memoizeOne((hover: boolean, loading: boolean = false, disabled: boolean = false, reversed: boolean = false) => {
        return classNames({
            hover,
            loading,
            disabled,
            reversed,
        });
    })

    /** function to return classes default and by parameters */
    public getClasses = memoizeOne((className?: string) => {
        return classNames(styles.button, className);
    });

    /** add an action to click event.
     * action is a prop that contains a function to be triggered.
     */
    public click = (event: React.MouseEvent) => {
        // Prevent click when disabled
        if (this.props.disabled) {
            if (event && event.preventDefault) {
                event.preventDefault()
            }
            return
        }

        if (this.props.action) {
            try {
                this.props.action(this.props);
            } catch (error) {
                this.fw.log.info(error);
            }

        }

        if (this.props.onClick) {
            this.props.onClick(event, this.props)
        }
    }

    public hover = (e: React.MouseEvent) => {
        if (!this.props.isLoading && !this.props.disabled) {
            this.setState({
                hover: true,
            });

            if (this.props.onMouseOver) {
                this.props.onMouseOver(e, this.props)
            }
        }
    }

    public leave = (e: React.MouseEvent) => {
        if (this.state.hover === true) {
            this.setState({
                hover: false,
            });

            if (this.props.onMouseLeave) {
                this.props.onMouseLeave(e, this.props)
            }
        }
    }

    /** function to render text + icon */
    public renderContent = () => {
        const { text, icon, isLoading, loadingComponent } = this.props;

        return (
            <React.Fragment>
                {!isLoading && text}
                {!isLoading && this.props.children}
                {(icon && !isLoading) && icon}
                {isLoading && loadingComponent}
            </React.Fragment>
        )
    }

    public render() {
        const classes = `_7g-button ${this.getClasses(this.props.className || this.props.classes)}`;
        const { isLoading, inputSubmit } = this.props;
        const effectClasses = this.getEffectClasses(this.state.hover, isLoading, this.props.disabled, this.props.reverseIcon);

        return (
            <button
                type={(inputSubmit ? "submit" : "button")}
                className={`${classes} ${effectClasses}`}
                onMouseOver={this.hover}
                onMouseLeave={this.leave}
                onClick={this.click}
                disabled={this.props.disabled}
                aria-label={this.props.ariaLabel}
            >
                <span>
                    {this.renderContent()}
                </span>
            </button>
        );
    }
}

export const ButtonComponent = Button
