import { BaseModel } from "@7egend/web.core.dlos";
import { Language } from "@7egend/web.core.geo/lib/dlos/language";
import { Group } from "./group";
import { DloInput, DloOutput } from "@7egend/web.core/lib/extensions/dlo";
import { Container } from "./container";

export interface Section extends BaseModel {
    name: string
    slug: string
    language?: Language
    group?: Group
}

export class SectionGetContainersInput extends DloInput<{
    slug: string,
}> {
    public uri = "cms-sections/${slug}/containers/digested"
}

export class SectionGetContainersOutput extends DloOutput<Container[]> { }
