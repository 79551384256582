import { DloInput, DloOutput } from "@7egend/web.core/lib/extensions/dlo";
import { BaseModel } from "@7egend/web.core.dlos";
import { PostType } from "./postType";
import { PostSource } from "./postSource";
import { Medium } from "@7egend/web.core.media/lib/dlos/medium";

export interface Post extends BaseModel {
    uuid?: string;
    medium?: Medium;
    media?: Medium[];
    type: PostType;
    source: {
        id: PostSource;
        name: string
    }
    external_id: string;
    url: string;
    published_at: string;
    text?: string;
    date?: string;
    title?: string;
}

export class PostGetAllInput extends DloInput {
    public channelId = "1";
    public sourceId: string | undefined;
    public uri = "club-hub-channels/${channelId}/posts/${sourceId}?fields=${fields}";
    public fields = "uuid,text,url,source(id,name),medium(id,title,date,medium_type,image,url)";
}

export class PostGetAllOutput extends DloOutput<Post[]> {
}
